<template>
    <div>
        <BModal
            v-if="job.id"
            :id="`jobModal${job.id}`"
            auto-focus-button="ok"
            cancel-variant="secondary"
            modal-class="bar bar-primary"
            no-close-on-backdrop
            static
            visible
            size="lg"
            :title="`Job ${job.id} Detail`"
            @hide="hide"
        >
            <template
                slot="modal-header-close"
            >
                <FontAwesomeIcon
                    data-cy="jobModalCloseButton"
                    icon="times"
                    size="lg"
                />
            </template>

            <BFormGroup
                v-for="key in Object.keys(job)"
                :key="key"
                :data-cy="`jobModal${startCase(key)}FormGroup`"
                label-cols-lg="3"
            >
                <template slot="label">
                    {{ startCase(key) }}
                </template>

                <BInput
                    :data-cy="`jobModal${startCase(key)}Input`"
                    readonly
                    :value="typeof(job[key]) === 'object' ? JSON.stringify(job[key]) : (job[key] || '(not set)')"
                />
            </BFormGroup>

            <div class="row mt-4">
                <div class="col">
                    <BButton
                        v-b-toggle.jobObjectCollapse
                        data-cy="jobModalViewRawButton"
                        variant="secondary"
                    >
                        <FontAwesomeIcon
                            icon="brackets-curly"
                            class="mr-2"
                        />

                        View Raw Job Object
                    </BButton>
                </div>
            </div>

            <BCollapse
                id="jobObjectCollapse"
                data-cy="jobModalViewRawCollapse"
            >
                <div class="bg-dark rounded p-2 mt-2">
                    <pre class="text-light mt-2">{{ job }}</pre>
                </div>
            </BCollapse>

            <template #modal-footer="{ok}">
                <BButton
                    data-cy="jobModalDoneButton"
                    variant="secondary"
                    @click="ok"
                >
                    Done
                </BButton>
            </template>
        </BModal>
    </div>
</template>

<script>
    import toastsMixin from '@imt/vue-toolbox/src/mixins/toasts';
    import startCase from 'lodash/startCase';
    import {mapActions, mapMutations} from 'vuex';

    import authMixin from '@/mixins/auth';

    export default {
        name: 'BHJobDetailsModal',
        mixins: [
            authMixin,
            toastsMixin,
        ],
        data() {
            return {
                job: {},
            };
        },
        async created() {
            await this.initialize();
        },
        methods: {
            async initialize() {
                this.SET_DATA({field: 'saving', data: true});

                try {
                    this.job = await this.fetchJob(this.$route.params.id);
                } catch {
                    await this.$router.push({name: 'admin.jobs.list'});
                    this.error('This job was not found or does not exist.', 'Job Not Found');
                }

                this.SET_DATA({field: 'saving', data: false});
            },
            async hide() {
                await this.$router.push({name: 'admin.jobs.list'});
            },
            startCase,
            ...mapActions(['fetchJob']),
            ...mapMutations('builder', ['SET_DATA']),
        },
    };
</script>
